import React from 'react';

const Projects = () => {
  return (
    <section className="projects-main bg-white" id="work">
      <div className="text-dark d-flex justify-content-center pt-3">
        <h2 className="category-underline">Work</h2>
      </div>
      <div className="row row-cols-1 row-cols-md-2 g-4 pt-5 text-dark">
        <div className="col">
          <div className="card grow fade-in">
            <img
              src="gratitudejournalpreview.jpg"
              className="card-img-top"
              alt="..."
            />
            <div className="card-body">
              <h5 className="card-title mt-2 mb-3">My Gratitude Journal</h5>
              <span className="badge rounded-pill text-bg-secondary me-1 mt-2">
                React.JS
              </span>
              <span className="badge rounded-pill text-bg-secondary me-1 mt-2">
                Node.JS
              </span>
              <span className="badge rounded-pill text-bg-secondary me-1 mt-2">
                MongoDB
              </span>
              <span className="badge rounded-pill text-bg-secondary mt-2">
                Express.JS
              </span>
              <p className="card-text text-muted mt-3">
                A full-stack React.JS site which allows users to store their
                daily gratitudes. App includes registration, login, and JWT
                authentication with Node.js and Express.
              </p>
              <div className="project-buttons">
                <a
                  href="https://gratefuljoyfuljournal.us/"
                  className="btn btn-outline-primary"
                  target="_blank"
                  rel="noreferrer"
                >
                  View Live Site
                </a>
                <a
                  href="https://github.com/jkol7/mygratitudejournal"
                  className="btn btn-outline-secondary ms-lg-3"
                  target="_blank"
                  rel="noreferrer"
                >
                  View Github
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card grow">
            <img src="lotusiopreview.jpg" className="card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title mt-2 mb-3">Lotus.io</h5>
              <span className="badge rounded-pill text-bg-secondary me-1 mt-2">
                React.JS
              </span>
              <span className="badge rounded-pill text-bg-secondary me-1 mt-2">
                Node.JS
              </span>
              <span className="badge rounded-pill text-bg-secondary me-1 mt-2">
                MaterialUI
              </span>
              <span className="badge rounded-pill text-bg-secondary me-1 mt-2">
                Express.JS
              </span>
              <p className="card-text text-muted mt-3">
                A React.JS yoga resource that presents yoga pose and Youtube API
                data. Front-end created using the Material-UI library. Back-end
                created with Node.js and Express.
              </p>
              <div className="project-buttons">
                <a
                  href="https://lotusyogalibrary.com/"
                  className="btn btn-outline-primary"
                  target="_blank"
                  rel="noreferrer"
                >
                  View Live Site
                </a>
                <a
                  href="https://github.com/jkol7/lotus-io"
                  className="btn btn-outline-secondary ms-lg-3"
                  target="_blank"
                  rel="noreferrer"
                >
                  View Github
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card grow">
            <img src="ceelopreview.jpg" className="card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title mt-2 mb-3">Cee-lo</h5>
              <span className="badge rounded-pill text-bg-secondary me-1 mt-2">
                React.JS
              </span>
              <span className="badge rounded-pill text-bg-secondary me-1 mt-2">
                HTML/CSS
              </span>
              <p className="card-text text-muted mt-3">
                Programmed a React.js dice game against a computer opponent with
                seven different winning conditions. Deployed to Heroku.
              </p>
              <div className="project-buttons">
                <a
                  href="https://cee-lo-fullstack.herokuapp.com/"
                  className="btn btn-outline-primary"
                  target="_blank"
                  rel="noreferrer"
                >
                  View Live Site
                </a>
                <a
                  href="https://github.com/jkol7/cee-lo"
                  className="btn btn-outline-secondary ms-lg-3"
                  target="_blank"
                  rel="noreferrer"
                >
                  View Github
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card grow">
            <img
              src="trendingtvpreview.jpg"
              className="card-img-top"
              alt="..."
            />
            <div className="card-body">
              <h5 className="card-title mt-2 mb-3">Today's Trending Shows</h5>
              <span className="badge rounded-pill text-bg-secondary me-1 mt-2">
                JavaScript
              </span>
              <span className="badge rounded-pill text-bg-secondary me-1 mt-2">
                HTML/CSS
              </span>
              <p className="card-text text-muted mt-3">
                Integrated data from The Movie Database API to view top trending
                shows and details on each show like poster, network, and
                homepage link.
              </p>
              <div className="project-buttons">
                <a
                  href="https://trendingstreamingshows.herokuapp.com/"
                  className="btn btn-outline-primary"
                  target="_blank"
                  rel="noreferrer"
                >
                  View Live Site
                </a>
                <a
                  href="https://github.com/jkol7/TrendingTVShow"
                  className="btn btn-outline-secondary ms-lg-3"
                  target="_blank"
                  rel="noreferrer"
                >
                  View Github
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
