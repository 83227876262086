import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import Resume from '../Assets/JohnKolcun-Resume-FullStack-SoftwareEngineer.pdf';

const Contact = () => {
  return (
    <section className="contact-main bg-white d-flex" id="contact">
      <div className="contact-container text-dark px-5">
        <div className="d-flex flex-column">
          <h3 className="category-underline">Let's work together</h3>
          <Tippy content="Click to copy" placement="right">
            <h4
              className="pt-3 email"
              onClick={() =>
                navigator.clipboard.writeText('jkolcun7@gmail.com')
              }
            >
              jkolcun7@gmail.com
              <i class="bi bi-clipboard2-plus ps-2"></i>
            </h4>
          </Tippy>
        </div>
        <a href={Resume} without rel="noopener noreferrer" target="_blank">
          <button className="btn btn-lg btn-outline-primary">
            View Resume
          </button>
        </a>
      </div>
      <div className="contact-container-bg">
        <div className="footer-icons d-flex flex-row justify-content-center mt-5">
          <a href="https://github.com/jkol7" target="_blank">
            <i className="bi bi-github me-4" style={{fontSize: '25px'}}></i>
          </a>
          <a href="https://www.linkedin.com/in/jkol7/" target="_blank">
            <i className="bi bi-linkedin me-4" style={{fontSize: '25px'}}></i>
          </a>
          <a href="https://twitter.com/sydroad" target="_blank">
            <i className="bi bi-twitter me-4" style={{fontSize: '25px'}}></i>
          </a>
        </div>
        <p className="d-flex justify-content-center pt-3 text-light text-muted">
          Created with React.JS and Bootstrap
        </p>
      </div>
    </section>
  );
};

export default Contact;
